/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.


	function stickyFooter () {
		var windowHeight = $(window).height();
		var bodyHeight = $('body').outerHeight();
		if (windowHeight > bodyHeight) {
			var hauteurMain = windowHeight - $('header').outerHeight() - $('footer').outerHeight();
			$('main').css('min-height', hauteurMain + 'px');
		}
	}

	var Sage = {
		// All pages
		'common': {
			init: function () {
				//JavaScript to be fired on all pages

				$('.jscroll > a').on('click', function() { //smooth scroll pour les liens du menu
					var page = '#'+$(this).attr('rel');
					var speed = 750;
					//$('html, body').animate( { scrollTop: $(page).offset().top - $('header > .navbar').height() }, speed );
					$('html, body').animate( { scrollTop: $(page).offset().top - $('header.banner').height() - 20 }, speed );
					return false;
				});


				if(window.location.hash) { //smooth scroll pour les liens externes (au chargement de la page)

					if($(window.location.hash).length) {
						$("html, body").animate({
							//scrollTop: $(window.location.hash).offset().top - $('header > .navbar').height()
							scrollTop: $(window.location.hash).offset().top - $('header.banner').height() - 20
						}, 750);
					}
				}


				stickyFooter();

				$(document).click(function (e) {
					if (!$(e.target).is('.menu-collapse, .menu-item, .menu')) {
						$('.menu-collapse').collapse('hide');
						//console.log(e.target);
					}
				});

				$('.open-menu').click(function () {

					var idDiv = $(this).attr('data-iddiv');
					$('.collapse').collapse('hide');
					$('#' + idDiv).collapse('show');
					$('.open-menu').removeClass('active');
					$('#' + idDiv + '-btn').addClass('active');
				});
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired

				$(".chiffre-counter").appear(function () {
					var id = $(this).attr('id');
					var suffixe = $(this).attr('data-suffixe');
					var chiffre = $(this).attr('data-counter');
					var prefixe = $(this).attr('data-prefixe');
					var compteur = new CountUp(id, 0, chiffre, 0, 2.5, {
						separator: ' ',
						suffix: suffixe,
						prefix: prefixe
					});
					compteur.start();
				});

				$('.owl-logo').owlCarousel({
					nav: true,
					loop: true,
					margin: 70,
					autoWidth: true,
					items: 6,
					autoplay: true,
					smartSpeed: 1000,
					autoplayTimeout: 2000,
					navText: ['<i class=" fa fa-chevron-circle-right"></i>', '<i class=" fa fa-chevron-circle-left"></i>'],


					responsive: {
						0: {
							items: 1
						},
						600: {
							items: 3
						},
						1000: {
							items: 5
						}
					}
				});

			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'archive': {
			init: function () {
				// JavaScript to be fired on the about us page

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
